<template>
    <div
        class="inline-loading"
    >
        Загрузка...
    </div>
</template>

<style lang="stylus" scoped>
.inline-loading
    width 100%
    height 100%
    display flex
    align-items center
    justify-content center
</style>
