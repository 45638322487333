import { parseBaseError } from 'parse-base-error';
import { has } from '@/utils/has';
import { isObject } from '@/utils/predicate/isObject';

export const parseAxiosError = (e: unknown) => {
  let errorData;

  if (
    isObject(e)
        && has('response', e)
        && isObject(e.response)
        && has('data', e.response)
  ) {
    errorData = e.response.data;
  }

  return parseBaseError(e, 'AxiosRequest', errorData);
};
