import { createRequest, requestAssert } from 'http-client-wrapper';
import { httpClient } from '@/api/httpClient';
import { config } from '@/config';
import { Account } from '@/api/types/Account';
import { delay } from '@/utils/delay';
import { Milliseconds } from '@/constants/timeConstants';

export const authTgWebApp = createRequest(async ({
  initData,
  tgBotId,
}: {
  initData: string,
  tgBotId: number | undefined
}) => {
  const response = await httpClient.postRequest<Account>(`${config.api}/auth/tg-web-app`, {
    params: {
      initData,
      tgBotId,
    },
  });

  requestAssert(response, 'authTgWebApp');

  return response.data;
}, {
  attempts: 15,
  repeatStrategy: async (currentAttempts) => {
    await delay(Milliseconds.IN_SECOND);

    console.log('repeatStrategy', currentAttempts);

    return true;
  },
});
