import axios, { AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import { HttpClientWrapperMethod, HttpClientWrapperRequestOptions } from 'http-client-wrapper';
import { parseAxiosError } from '@/plugins/axios/parseAxiosError';

export const createAxiosRequest = <T = any>(config?: AxiosRequestConfig<T>) => {
  const axiosInstance = axios.create({
    timeout: 60000,
    ...config,
  } as CreateAxiosDefaults);

  return async (url: string, options: HttpClientWrapperRequestOptions) => {
    try {
      const requestOptions = {
        url,
        method: options.method,
        headers: options.headers,
        withCredentials: options.withCredentials,
        ...options.timeout ? { timeout: options.timeout } : {},
        ...options.method === HttpClientWrapperMethod.GET ? {
          params: options.queryParams ?? options.params,
        } : {
          data: options.params,
          params: options.queryParams,
        },
      };
      const { data } = await axiosInstance.request(requestOptions);

      return data;
    } catch (e) {
      throw parseAxiosError(e);
    }
  };
};
