<template>
  <router-view />
</template>

<style lang="stylus">
#app
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
    color var(--tg-theme-text-color)
    width 100%
    max-width 560px
    height var(--tg-viewport-stable-height)
    max-height var(--tg-viewport-stable-height)
    margin 0 auto
    background-color var(--tg-theme-bg-color)
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;

body
    background-color var(--tg-theme-bg-color)

</style>
