import { useRoute } from 'vue-router';
import { createSharedComposable } from '@/features/base/createSharedComposable';
import { config } from '@/config';

export const sharedConfig = createSharedComposable(() => {
  const route = useRoute();
  const isDev = Boolean(config.isDev || route.query.dev);
  const queryId = route.query.id;
  const queryData = route.query.data;
  const queryPartnerId = route.query.partnerId;

  return {
    isDev,
    queryId,
    queryPartnerId,
    queryData,
  };
});
