export const Hours = {
  IN_MONTH: 31 * 24,
  IN_DAY: 24,
};

export const Minutes = {
  IN_MONTH: Hours.IN_MONTH * 60,
  IN_DAY: Hours.IN_DAY * 60,
  IN_HOUR: 60,
};

export const Seconds = {
  IN_MONTH: Minutes.IN_MONTH * 60,
  IN_DAY: Minutes.IN_DAY * 60,
  IN_HOUR: Minutes.IN_HOUR * 60,
  IN_MINUTE: 60,
};

export const Milliseconds = {
  IN_MONTH: Seconds.IN_MONTH * 1000,
  IN_DAY: Seconds.IN_DAY * 1000,
  IN_HOUR: Seconds.IN_HOUR * 1000,
  IN_MINUTE: Seconds.IN_MINUTE * 1000,
  IN_SECOND: 1000,
};
